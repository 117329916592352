export const query = `query getCheckoutSettings {
  checkoutSettings {
    mitEnabled
    taxOnProduct
    termsAndConditions {
      value
      enabled
    }
    privacyPolicy {
      value
      enabled
    }
    digitalItemPolicy {
      value
      enabled
    }
    refundCancellationPolicy {
      value
      enabled
    }
    contactUs {
      value
      enabled
    }
    customPolicy {
      value
      enabled
      title
    }
    logo {
      url
      mediaType
      title
      width
      height
    }
    storeName
    checkoutPhone {
      mandatory
      show
    }
    checkoutCustomField {
      mandatory
      show
      title
    }
    checkoutAddressLine2 {
      mandatory
      show
    }
    checkoutCompanyName {
      mandatory
      show
    }
    checkoutPolicyCheckbox {
      checked
      show
    }
    checkoutSubscriptionCheckbox {
      checked
      show
    }
    checkoutCustomField {
      mandatory
      show
      title
      untranslatedTitle
    }
    checkoutGiftCardCheckbox {
      show
      supported
    }
  }
  payments {
    activePaymentMethods {
      name
      type
    }
  }
  localDeliveryByZipCodeFlag {
    localDeliveryByZipCodeExists
  }
  destinations {
    locations {
      code
    }
  }
}`;
